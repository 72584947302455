import React from "react";
import Typography from "@mui/material/Typography";
import ImageListItem from "@mui/material/ImageListItem";
import Button from "@mui/material/Button";
import ArtWorks from "../../assets/art-works.jpg";
import MuralArts from "../../assets/mural-arts.jpg";
import Sculptures from "../../assets/sculptures.jpeg";
import Itservices from "../../assets/Itservices.jpeg";
import DesignS from "../../assets/designS.jpeg";

const itemData = [
  {
    img: DesignS,
    title: "3D Design Software developement",
  },
  {
    img: ArtWorks,
    title: "Art works",
  },
  {
    img: MuralArts,
    title: "Mural Arts",
  },
  {
    img: Sculptures,
    title: "Sculptures",
  },
  {
    img: Itservices,
    title: "IT Services",
  },
];

// const buttonStyles = {
//   display: "flex",
//   justifyContent: "center",
//   marginTop: "30px",
// };

const Advantages = () => {
  return (
    <div style={{ marginTop: "50px" }}>
      <Typography variant="h4" component="h5">
        FRP Advantages
      </Typography>
      <div style={{ marginTop: "12px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {itemData.map((item) => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageListItem
                key={item.title}
                style={{ width: "140px", height: "140px" }}
              >
                <img
                  style={{ borderRadius: "50%" }}
                  src={`${item.img}`}
                  alt="frp advantages"
                  loading="lazy"
                />
              </ImageListItem>
              <Typography
                variant="h6"
                component="h6"
                sx={{
                  marginTop: "20px",
                  fontSize: "1.10rem",
                  marginBottom: "20px",
                }}
              >
                {item.title}
              </Typography>
            </div>
          ))}
        </div>
      </div>
      {/* <div style={buttonStyles}>
        <Button variant="outlined">View All advantages</Button>
      </div> */}
    </div>
  );
};

export default Advantages;
