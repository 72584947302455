import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Carousel1 from "../../assets/carousel/carousel1.jpg";
import Carousel2 from "../../assets/carousel/carousel2.jpg";
import Carousel3 from "../../assets/carousel/carousel3.jpg";
import Carousel4 from "../../assets/carousel/carousel4.jpg";
import Carousel5 from "../../assets/carousel/carousel5.jpg";
import Carousel6 from "../../assets/carousel/carousel6.jpg";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Mind Labs1",
    imgPath: Carousel1,
  },
  {
    label: "Mind Labs2",
    imgPath: Carousel2,
  },
  {
    label: "Mind Labs3",
    imgPath: Carousel3,
  },
  {
    label: "Mind Labs4",
    imgPath: Carousel4,
  },
  {
    label: "Mind Labs5",
    imgPath: Carousel5,
  },
  {
    label: "Mind Labs6",
    imgPath: Carousel6,
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  height: 455,
                  display: "block",
                  overflow: "hidden",
                  width: "100%",
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
        />
      </div>
    </Box>
  );
}

export default SwipeableTextMobileStepper;
