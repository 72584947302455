import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
// import Typography from "@mui/material/Typography";
// import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
// import Button from "@mui/material/Button";
// import MenuItem from "@mui/material/MenuItem";
import Logo from "../../assets/logo2.jpeg";

// const pages = [
//   "HOME",
//   "ABOUT",
//   "PRODUCTS",
//   "MACHINARY TOOLS",
//   "SERVICES",
//   "CONTACT US",
// ];

const Navbar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [value, setValue] = React.useState("HOME");

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (e) => {
    setAnchorElNav(null);
    setValue(e.target.innerText);
  };
  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{ backgroundColor: "white", color: "black" }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            component="img"
            sx={{
              ml: 8,
              display: { xs: "none", md: "flex" },
              padding: "6px",
              fontWeight: 700,
              letterSpacing: ".1rem",
              height: "60px",
              borderRadius: "4px",
              margin: "auto",
              color: "white",
              textDecoration: "none",
            }}
            src={Logo}
            alt="MindLabs Logo"
          />

          {/* <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton> */}
          {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu> */}
          {/* </Box> */}
          <Box
            component="img"
            sx={{
              // mr: 8,
              display: { xs: "flex", md: "none" },
              // flexGrow: 1,
              fontWeight: 700,
              margin: "auto",
              padding: "6px",
              width: "180px",
              height: "50px",
            }}
            src={Logo}
            alt="MindLabs Logo"
          />
          {/* <Box
            sx={{
              flexGrow: 1,
              justifyContent: "end",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleCloseNavMenu}
                sx={{
                  my: 2,
                  mr: 2,
                  color: value === page ? "white" : "black",
                  letterSpacing: "0.5px",
                  display: "block",
                  backgroundColor: value === page ? "royalblue" : "",
                }}
              >
                {page}
              </Button>
            ))}
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
